@tailwind utilities;

$colors: (
  blue: #1d35ee,
  dark-blue: #1024a3,
  light-blue: #00c0e9,
  darker-gray: #101015,
  dark-gray: #1b1b22,
  gray: #6a6b70,
  light-gray: #aeaeae,
  red: #ff1463,
  orange: #ff7500,
  yellow: #edea00,
  green: #7ed321,
  white: #ffffff,
  black: #000000,
  'red-500': #ef4444,
  'red-100': #fee2e2,
  'blue-500': #3b82f6,
  'blue-100': #dbeafe,
  'yellow-600': #ca8a04,
  'yellow-50': #fefce8,
);

@mixin color-modifiers($attribute: 'color', $prefix: '-') {
  @each $name, $hex in $colors {
    &#{$prefix}#{$name} {
      #{$attribute}: $hex;
    }
  }
}

@layer utilities {
  .text {
    @include color-modifiers();
  }

  .bg {
    @include color-modifiers($attribute: 'background-color');
  }

  .border {
    @include color-modifiers($attribute: 'border-color');
  }

  .border-b {
    @include color-modifiers($attribute: 'border-bottom-color');
  }

  .border-t {
    @include color-modifiers($attribute: 'border-top-color');
  }

  .border-l {
    @include color-modifiers($attribute: 'border-left-color');
  }

  .border-r {
    @include color-modifiers($attribute: 'border-right-color');
  }

  .border-x {
    @include color-modifiers($attribute: 'border-right-color');
    @include color-modifiers($attribute: 'border-left-color');
  }

  .border-y {
    @include color-modifiers($attribute: 'border-top-color');
    @include color-modifiers($attribute: 'border-bottom-color');
  }
}
