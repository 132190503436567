@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    overflow-x: hidden;
    padding-bottom: env(safe-area-inset-bottom);
  }

  body {
    position: fixed;
    font-family: 'Industry', 'sans-serif';
    background-color: #000000;
    color: #ffffff;
  }

  #root {
    height: 100vh;
    width: 100vw;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-lg;
  }

  p {
    @apply text-base text-light-gray;
  }

  label {
    @apply text-light-gray;
  }

  a {
    color: #00c0e9;
  }

  a:hover {
    opacity: theme('opacity.80');
  }
}

@layer components {
  .big-hero {
    font-size: 28px;
    line-height: 34px;
  }

  .product-description,
  .product-description p,
  .product-description ul li,
  .product-description table {
    @apply table-auto text-sm;
  }
  .product-description ul,
  .product-description li,
  .product-description ol {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
  .product-description table {
    @apply w-full text-center;
  }

  /* Masonry styles  */
  .scuti-masonry > div {
    @apply animate-comeIn;
  }
}

/* Toastify__toast */
.Toastify__toast {
  border-radius: 0;
  padding: 20px 22px;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .Toastify__toast-container--bottom-right {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    width: 100%;
  }
}

.Toastify__toast--success {
  background: #7ed321;
}

.Toastify__toast--error {
  background: #ff1463;
}

.Toastify__close-button {
  opacity: 1;
  align-self: center;
}

.Toastify__toast svg {
  fill: #ffffff;
  height: 18px;
  width: 18px;
}

/* Swiper styles  */
.swiper-pagination-bullet {
  background-color: #ffffff;
}

.swiper-pagination-bullet-active {
  background-color: #00c0e9;
}

.swiper-product-images .swiper-wrapper {
  display: flex;
  align-items: center;
}

/* Exchange page extra styles */
.exchange-page-video #adgSlate {
  z-index: 1000;
}
.exchange-page-video .adgTitleWrapper {
  background-color: #1b1b2200;
}

.exchange-page-video .adgAdText {
  color: #fff;
}
